import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { tablet } from '~styles/global'
import SanityLink from '~components/SanityLink'
import RichText from '~components/RichText'
import Image from '~components/Image'
import '@dotlottie/player-component'
import { navigate } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import ColumnSlider from '~components/ColumnSlider'
import { useSiteState } from '~context/siteContext'

const mobile = '@media (max-width: 880px)'

const Column = ({ className, place }) => {
	const [hover, setHover] = useState(false)
	const [animation, setAnimation] = useState()
	const animationRef = useRef()
	const [animating, setAnimating] = useState(false)
	const [siteState, setSiteState] = useSiteState()
	const [pageVisible, setPageVisible] = useState(true)

	const clickedRef = useRef(false)
	
	useEffect(() => {
		if(animationRef?.current){
			const player = animationRef?.current
			setAnimation(player)
		}
	}, [animationRef])

	useEffect(() => {
		// window.addEventListener('blur', () => setPageVisible(false))
		// window.addEventListener('focus', () => setPageVisible(true))

		// Handle page visibility change events
		function visibilityListener() {
			switch(document.visibilityState) {
			case 'hidden':
				setPageVisible(false)
				break
			case 'visible':
				window.setTimeout(() => setPageVisible(true), 300)
				break
			}
		}

		document.addEventListener('visibilitychange', visibilityListener)

	}, [])

	useEffect(() => {
		const handleComplete = () => {
			if(pageVisible){
				if (place.learnMoreLink.document) {
					navigate(resolveLink(place.learnMoreLink.document))
				} else {
					window.location.href = place.learnMoreLink.url
				}
			}
		}

		const handleFrame = e => {
			if(e.detail.frame > place.loopDuration && !clickedRef.current){
				animation.seek(0)
			}
		}

		if (animation) {
			animation.play()
			animation.addEventListener('frame', handleFrame)
			animation.addEventListener('complete', handleComplete)
		}

		return () => {
			animation?.removeEventListener('complete', handleComplete)
			animation?.removeEventListener('frame', handleFrame)
		}

	}, [animation, pageVisible])

	// useEffect(() => {
	// 	if(!animation) return
	// 	if(pageVisible){
	// 		// animation.play()
	// 		// console.log('play')
	// 	} else {
	// 		// alert('hello')
	// 		console.dir(animation.pause)
	// 		animation.pause()
	// 		console.log('pause')
	// 	}
	// }, [pageVisible, animation])

	const handleClick = () => {
		animation.seek(place.loopDuration)
		setAnimating(true)
		setSiteState(prevState => ({
			...prevState,
			loading: true,
		}))
		clickedRef.current = true
	}

	return (
		<>
			{clickedRef.current && 
				<Global
					styles={css`
					html * {
						cursor: wait !important;
					}
				`}
				/>
			}
			<Col 
				onMouseEnter={()=> setHover(true)}
				onMouseLeave={()=> setHover(false)}	
				css={css`:before{background: ${place.featureColor?.hex};}`}
			>
				<Target 
					onClick={()=> handleClick()}
					css={css`
						/* cursor: ${siteState.loading ? 'wait !important' : 'pointer'}; */
					`}
				>
					{place.title}
				</Target>
				<Title css={css`color: ${place.featureColor?.hex};`}>
					{place.title}
				</Title>
				<Text css={css`
					opacity: ${hover ? '1' : '0'};
					pointer-events: ${hover ? 'all' : 'none'};
					span {
						color: ${place.featureColor?.hex};
						a {
							&:after {
								background-color: ${place.featureColor?.hex};
							}
						}
					}
					${mobile}{
						opacity: 1;
					}
				`}>
					<RichText content={place.description}/>
				</Text>
				<ImageBlock>
					<GraphicContainer>
						<dotlottie-player
							ref={animationRef}
							src={place.lottie}
							autoplay
						/>
					</GraphicContainer>
					<Overlay 
						css={css`
						background-color: ${place.featureColor?.hex};
						opacity: ${hover && !animating ? '1' : '0'};
					`}
					/>
					<ColImage 
						css={css`
							opacity: ${hover && !animating ? '1' : '0'};
						`}
						image={place.hoverImage} 
						aspectRatio={1}
					/>
				</ImageBlock>
				<ImagesMobile image={place.hoverImage} lottie={place.lottie} featureColor={place.featureColor?.hex} duration={place.loopDuration}/>
				<LearnMore 
					link={place.learnMoreLink}
					className='h3' 
					css={css`
					color: ${place.featureColor?.hex}; 
					border-color: ${place.featureColor?.hex};
			`}>
					<Arrow>→</Arrow>Learn more
				</LearnMore>
			</Col>
		</>
	)
}

const Col = styled.div`
	grid-column: span 1;
	position: relative;
	/* padding-top: 2px; */
	display: grid;
	grid-template-rows: max-content max-content 1fr;
	height: 100%;
	min-height: calc(100vh - (var(--xl) * 2));
	&:before{
		content: '';
		width: 1px;
		position: absolute;
    left: -13px;
		bottom: 0;
    top: 0;
		${mobile}{
			content: none;
		}
	}
	${mobile}{
		padding-top: 0px;
		grid-template-rows: max-content max-content max-content;
		height: auto;
		min-height: auto;
		margin-bottom: 54px;
		&:first-of-type{
			margin-bottom: 38px;
		}
	}
`
const Title = styled.h2`
	font-weight: 600;
	margin-bottom: var(--l);
	${tablet}{
		width: min-content;
		display: table-caption;
		-ms-grid-columns: min-content;
	}
	${mobile}{
		margin-bottom: 118px;
		width: auto;
	}
`
const Target = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: 10;
	cursor: pointer;
	${mobile}{
		pointer-events: none;
	}
`
const Text = styled.h2`
	margin-bottom: var(--l);
	transition: opacity 0.25s;
	span {
		font-weight: 600;
		a {
			position: relative;
			text-decoration: underline;
		}
		${mobile}{
			display: none;
		}
	}
	${mobile}{
		opacity: 1;
		pointer-events: all;
	}
`
const ImageBlock = styled.div`
	margin-top: auto;
	position: relative;
	align-self: end;
	${mobile}{
		display: none;
	}
`
const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    mix-blend-mode: screen;
		transition: opacity 0.25s;
		${mobile}{
			display: none;
		}
`
const GraphicContainer = styled.div`
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
	display: block;
	${mobile}{
		position: relative;
	}
`
const ColImage = styled(Image)`
	transition: opacity 0.25s;
	img {
		filter: grayscale(1);
	}
`
const ImagesMobile = styled(ColumnSlider)`
	display: none;
	grid-row: 2;
	margin-bottom: 108px;
	${mobile}{
		display: block;
	}
`

const LearnMore = styled(SanityLink)`
	border-top: 1px solid;
	border-bottom: 1px solid;
	font-weight: 600;
	padding: var(--xxxs) 0;
	display: none;
	${mobile}{
		display: block;
	}
`
const Arrow = styled.span`
	display: inline-block;
	margin-right: var(--xxxs);
`

Column.propTypes = {
	className: PropTypes.string,
	place: PropTypes.object,
	key: PropTypes.string,
}
export default Column